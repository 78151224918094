import React from 'react'
import { CMSWrapper } from '../components/cmsWrapper'
import { Link } from 'gatsby'
import { Navigation } from '../common/navigation'
import { GlobalHelmet } from '../common/seo/globalHelmet'
import { Footer } from '../common/footer'

export default function Impressum() {
  return (
    <CMSWrapper>
      <GlobalHelmet title="UKE jukebox - Impressum" />
      <main>
        <Navigation />

        <header className="bg-blue text-center">
          <h1>Impressum</h1>
        </header>
        <section className="divider-right">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-12">
              <p>
                <b>Universitätsklinikum Hamburg-Eppendorf (UKE)</b>
                <br />
                Martinistraße 52
                <br />
                20246 Hamburg
                <br />
                <b>Telefon:</b>+49 (0) 40 7410 - 56061
                <br />
                <b>Email:</b> info@uke.de
              </p>

              <p>
                Das Universitätsklinikum Hamburg-Eppendorf ist eine rechtsfähige
                Körperschaft des öffentlichen Rechts und eine Gliedkörperschaft
                der Universität Hamburg. Zuständige Aufsichtsbehörde ist die
                Behörde für Wissenschaft, Forschung und Gleichstellung (BWFG),
                Hamburger Straße 37, 22083 Hamburg.
                <br />
                <br />
              </p>

              <p>
                <b>Vorstand:</b>
                <br />
                Prof. Dr. med. Christian Gerloff, Ärztlicher Direktor und
                Vorstandsvorsitzender
                <br />
                Joachim Prölß, Direktor für Patienten- und Pflegemanagement
                <br />
                Prof. Dr. Blanche Schwappach-Pignataro, Dekanin
                <br />
                Corinna Wriedt, Kaufmännische Direktorin
                <br />
                <br />
                <br />
                <b>Umsatzsteueridentifikationsnummer:</b>
                <br />
                DE 218618948
                <br />
                <br />
                <br />
                <b>Krankenhausaufsicht gem: § 5 HmbKHG:</b>
                <br />
                Bezirksamt Hamburg Nord, Fachamt Gesundheit Eppendorfer Landstr.
                59 20249 Hamburg
                <br />
                <br />
                <b>
                  Überblick zuständige Kammern und berufsrechtliche Regelungen:
                </b>
                <br />
                Die für das UKE tätigen Ärzte und Ärztinnen sind Mitglieder der
                Ärztekammer Hamburg
                <a href="https://www.aerztekammer-hamburg.org">
                  https://www.aerztekammer-hamburg.org
                </a>
                <br />
                <br />
                Berufsbezeichnung: Arzt / Ärztin (Bundesrepublik Deutschland).
                Informationen zu den berufsrechtlichen Regelungen finden Sie
                unter:
                <a href="https://www.bundesaerztekammer.de/recht">
                  https://www.bundesaerztekammer.de/recht
                </a>
                <br />
                <br />
                Die für das UKE tätigen Psychotherapeuten und
                Psychotherapeutinnen sind Mitglieder der Psychotherapeutenkammer
                Hamburg
                <a href="https://www.ptk-hamburg.de">
                  https://www.ptk-hamburg.de
                </a>
                <br />
                <br />
                Berufsbezeichnung: psychologischer Psychotherapeut /
                psychologische (Bundesrepublik Deutschland). Informationen zu
                den berufsrechtlichen Regelungen finden Sie unter:
                <a href="https://www.bptk.de/recht/aktuell.html">
                  https://www.bptk.de/recht/aktuell.html
                </a>
                <br />
                <br />
                Die für das UKE tätigen Zahnärzte und Zahnärztinnen sind
                Mitglieder der Zahnärztekammer Hamburg
                <a href="https://www.zahnaerzte-hh.de/home.html">
                  https://www.zahnaerzte-hh.de/home.html
                </a>
                <br />
                <br />
                Berufsbezeichnung: Zahnarzt / Zahnärztin (Bundesrepublik
                Deutschland). Informationen zu den berufsrechtlichen Regelungen
                finden Sie unter:
                <a href="https://www.bzaek.de/fuer-zahnaerzte/zahnaerztliche-berufsausuebung/berufsrecht.html">
                  https://www.bzaek.de/fuer-zahnaerzte/zahnaerztliche-berufsausuebung/berufsrecht.html
                </a>
                <br />
                <br />
                Die für das UKE tätigen Apotheker und Apothekerinnen sind
                Mitglieder der Apothekerkammer Hamburg
                <a href="https://www.apothekerkammer-hamburg.de">
                  https://www.apothekerkammer-hamburg.de
                </a>
                <br />
                <br />
                Berufsbezeichnung: Apotheker / Apothekerin (Bundesrepublik
                Deutschland). Informationen zu den berufsrechtlichen Regelungen
                finden Sie unter:
                <a href="https://www.abda.de/themen/recht/apotheke-recht">
                  https://www.abda.de/themen/recht/apotheke-recht
                </a>
                <br />
                <br />
                <b>Datenschutzerklärung:</b>
                <br />
                Die Datenschutzerklärung finden Sie
                <Link to="/datenschutz">hier</Link>!
              </p>
            </div>
          </div>
        </section>

        <section className="bg-blue divider-right">
          <div className="row align-items-center mt-2">
            <div className="col-md-10 offset-md-1 col-12 text-start">
              <h2>Bildnachweise</h2>

              <h3>Icons:</h3>

              <p>
                2440 Hand Drawn Doodle Icons Bundle by Creative Stall
                <br />
                <a href="https://crmrkt.com/V8DvPW">
                  https://crmrkt.com/V8DvPW
                </a>
              </p>

              <p>
                Hintergrund „Kreise“ Section Matching und HG innerhalb des
                Matchings
                <br />
                <a href="https://www.fotolia.com">www.fotolia.com</a>{' '}
                Fotolia-Datei: #190839995 | Urheber: Strawberry Blossom
              </p>

              <br />
              <h3>Background Matching:</h3>
              <p>
                Hintergrund Strahlen
                <br />
                <a href="https://www.freepik.com">www.freepik.com</a> Designed
                by macrovector
              </p>

              <p>
                Hintergrund Gold Konfetti
                <br />
                <a href="https://www.freepik.com">www.freepik.com</a> Designed
                by kjpargeter{' '}
              </p>

              <p>
                Hintergrund Dreiecke
                <br />
                Vector abstract seamless patterns set for kids nursery
                <br />
                <a href="https://www.fotolia.com">www.fotolia.com</a>{' '}
                Fotolia-Datei: #217649378 | Urheber: julia_khimich
              </p>

              <p>
                Hintergrund Kreise
                <br />
                <a href="https://www.fotolia.com">www.fotolia.com</a>{' '}
                Fotolia-Datei: #190839995 | Urheber: Strawberry Blossom
              </p>

              <p>
                Header-Bild Stationsseite ONKO C3B
                <br />
                <a href="https://www.fotolia.com">www.fotolia.com</a>{' '}
                Fotolia-Datei: #94382443 | Urheber: Binetti Roberto;
                robertobinetti70
              </p>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </CMSWrapper>
  )
}
